import styled from 'styled-components';

export const VideoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  aspect-ratio: 1440/1920;
  overflow: hidden;
`;
export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
