import React from 'react';
import Spinner from '@/components/atoms/Spinner';

import { StyledBodyHeader } from './styles';

const LoadingIndicator = ({ children, className }) => {
  return (
    <StyledBodyHeader className={className}>
      <Spinner />
      {children}
    </StyledBodyHeader>
  );
};
export default LoadingIndicator;
