import styled from 'styled-components';
import { BodyHeader } from '@/components/StyledComponents';

import { AnimationIntro } from '@/components/StyledComponents';

export const StyledBodyHeader = styled(BodyHeader)`
  margin-top: 115px;
  opacity: 0;
  transform: translateY(10vh);
  animation: 0.5s ${AnimationIntro} ease-out forwards;
  img {
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 20px;
  }
`;
