import styled, { keyframes } from 'styled-components';
import { AnimationIntro } from '@/components/StyledComponents';
const AnimationLayout = keyframes`
  from {
    opacity:0;
    margin-left: 1vh;
  }

  to {
    opacity:1;
    margin-left: 0;
  }
`;
export const Wrapper = styled.main`
  margin: auto 0;
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  text-align: center;
  position: static;
  margin-top: 0;
  // opacity: 0;
  // transform: translateX(1vw);
  animation: ${AnimationLayout} 0.5s 0s ease-out 1 forwards;
  p @media (orientation: portrait) {
    margin-bottom: 25vh;
  }
`;
export const HeaderTitle = styled.img`
  width: 583px;
  margin-top: 280px;
  opacity: 0;
  animation: ${AnimationIntro} 0.5s 0s ease-out 1 forwards;
`;
