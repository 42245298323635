import styled from 'styled-components';

import Layout from '@/components/atoms/Layout';
import FramedVideo from '@/components/atoms/FramedVideo';

import LoadingIndicator from '@/components/molecules/LoadingIndicator';

export const StyledFramedVideo = styled(FramedVideo)`
  max-width: 440px;
  width: 100%;
  margin-right: 45px;
  ${({ pointer }) => pointer && `cursor:pointer;`}
`;

export const StyledFramedVideoNFT = styled(FramedVideo)`
  max-width: 440px;
  width: 100%;
  margin-right: 45px;
  ${({ pointer }) => pointer && `cursor:pointer;`}
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1110px;
  margin: 0 auto;
  animation: none;
`;
export const Col = styled(Row)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
`;
export const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;
export const LayoutClaim = styled(Layout)`
  text-align: center;
  .rowNFT {
    margin-top: 65px;
  }
  h2 {
    margin-bottom: 22px;
  }
  p {
    margin-bottom: 36px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .headerTitle {
    margin-top: 46px;
    margin-bottom: 64px;
    max-width: 100vw;
  }
  .bodyHeader {
    max-width: 100%;
    margin-bottom: 33px;
    a {
      font-weight: bold;
    }
  }
  input {
    margin-bottom: 12px;
    width: 90% !important;
    &.inputWallet {
      margin-top: 0;
    }
  }
  label {
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 0.15rem;
    line-height: 1.3125rem;
    max-width: 100%;
    font-size: 0.9rem;
    margin-bottom: 24px;
  }
  footer {
    font-size: 0.75rem;
    a {
      text-decoration: underline;
    }
    &.mobileOnly {
      display: none;
    }
  }
  @media (max-width: 1280px) {
    .headerTitle {
      max-width: 95vw;
      margin-bottom: 10px;
    }

    footer,
    ${Row}, .framedVideo {
      max-width: Calc(100vw - 100px);
    }
    ${Row} {
      flex-direction: column;
      justify-content: center;
    }
    .framedVideo {
      width: 450px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    footer {
      margin: 4rem auto 1rem;
      line-height: 1rem;
      display: none;
      &.mobileOnly {
        display: block;
      }
    }
  }

  @media (max-width: 640px) {
  }
`;
