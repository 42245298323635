import styled from 'styled-components';

export const Legal = styled.p`
  font-size: 0.5rem;
  line-height: 0.75rem;

  width: 67.4vw;
  margin: 1rem auto;
`;
export const LoginTitle = styled.h2`
  margin-top: 36px;
`;
