import React, { useContext } from 'react';

import { PageContext } from '@/context';
import { navigate, useStaticQuery, graphql } from 'gatsby'; // to query for image data

import { BodyWrapper, BackgroundTitle } from '@/components/StyledComponents';
import FlyingCardCollection from '@molecules/FlyingCardCollection';

import { StyledLayout, Logo } from './styles';
import { sendEmail } from '@/utils';

// import { sendEmail } from '@/utils';
const pageValues = {
  title: 'Intro',
};
import srcLogo from '@/images/ferragamo.svg';

const Intro = ({ headerIntro = false }) => {
  const context = useContext(PageContext);
  return (
    <StyledLayout
      pageTitle={pageValues.title}
      onClick={() => {
        if (process.env.GATSBY_KEEP_KIOSK === 'true') {
          navigate('/kiosk/');
        }
      }}
      className="layout_intro">
      <BodyWrapper>
        {headerIntro && (
          <>
            <Logo className="logo">
              <img src={srcLogo} alt="logo" />
            </Logo>
          </>
        )}
        <p>Capture a Professional Video by visiting us at 63 Greene St in Soho!</p>
      </BodyWrapper>
    </StyledLayout>
  );
};

export default Intro;
