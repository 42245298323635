import styled from 'styled-components';

export const CardWrapper = styled.div`
  max-height: 100vh;
  aspect-ratio: 1440/1920;

  position: relative;
  margin: 0 auto;
`;
export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: 0;
  left: 0;
`;
