import React from 'react';

import { VideoWrapper, StyledVideo } from './styles';

export const FramedVideo = ({ alt = '', key, onClick, src, className }) => {
  return (
    <VideoWrapper className={className} onClick={onClick}>
      <StyledVideo src={src} muted={true} playsInline={true} loop={true} autoPlay={true} />
    </VideoWrapper>
  );
};

export default FramedVideo;
