import styled, { keyframes } from 'styled-components';

import Layout from '@atoms/Layout';

import { AnimationIntro } from '@components/StyledComponents';
export const StyledLayout = styled(Layout)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    max-width: Calc(100vw - 26rem);
  }
  .artist {
    font-size: 12rem;
    margin-top: -16px;
  }
  p {
    margin: 0 auto;
    max-width: 600px;
    opacity: 0;
    transform: translateY(10vh);
    animation: ${AnimationIntro} 0.5s 0.25s ease-out 1 forwards;
  }
`;

export const Logo = styled.div`
  width: 471px;
  height: auto;
  max-width: Calc(100vw - 4rem);
  margin: 0 auto 0;
  aspect-ratio: 721/144;

  opacity: 0;
  transform: translateY(10vh);
  animation: ${AnimationIntro} 0.5s 0s ease-out 1 forwards;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 57px;
    width: 63vw;
  }
`;
