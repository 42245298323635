import React, { useState, useContext, useEffect, useRef } from 'react';
import slugify from 'slugify';
import Layout from '@atoms/Layout';
import { PageContext } from '@/context';
import FlyingCardCollection from '@molecules/FlyingCardCollection';
import { updateProfileSelectedNFT, getAssetKey } from '@/utils';

import { getSelectedAssetName, submitEmail } from '@/utils';

import {
  CarouselWrapper,
  CardCarousel,
  OptionWrapper,
  Swatch,
  StyledCard,
  Label,
  SwatchBorder,
  SwatchWrapper,
  Pagination,
  PaginationItem,
  PaginationTouchBox,
  OptionsFooter,
  LayoutOptions,
  CardWrapper,
  StyledFramedVideo,
  CardWrapperPreview,
  CameraPathExample,
} from './styles';
import { BodyWrapper, BodyHeader, StepTitle } from '@/components/StyledComponents';

import LoadingIndicator from '../../LoadingIndicator';

import cameraPathExample from '@/images/cameraPathExample.svg';
enum LOCAL_SLIDES {
  BASE = 0,
  CONFIRM = 1,
}
const Page = ({}) => {
  const refCarousel = useRef(null);
  const slideOrder = ['base', 'confirm'];
  const context = useContext(PageContext);
  let { broadcastMSG, AssetSelection, setParentSlide, clickNext, stateProfile, setStateProfile } = context;
  const [stateLocalSlide, setStateLocalSlide] = useState(LOCAL_SLIDES.BASE);
  const [stateOptions, setStateOptions] = useState(AssetSelection.options);
  const [stateLastOptions, setStateLastOptions] = useState(AssetSelection.options);

  const nextLocalSlide = () => {
    setStateLocalSlide(stateLocalSlide + 1);
  };
  const prevLocalSlide = () => {
    setStateLocalSlide(stateLocalSlide - 1);
  };

  const updateSelection = (target, value) => {
    const newOptions = { ...stateOptions };
    newOptions[target].selected = value;
    setStateOptions(newOptions);
  };
  useEffect(() => {
    scrollToPage(AssetSelection.options.base.selected);
  }, []);
  /*Update the context with the new selection whenever stateOptions changes*/
  useEffect(() => {
    if (stateOptions && AssetSelection) {
      if (getAssetKey(stateLastOptions) !== getAssetKey(stateOptions)) {
        broadcastMSG({ STATE_SET_NFT: getSelectedAssetName(stateOptions) });
      }
    }

    AssetSelection = { ...AssetSelection, options: { ...stateOptions } };
    setStateLastOptions(JSON.parse(JSON.stringify(stateOptions)));
  }, [stateOptions]);

  useEffect(() => {
    if (stateLocalSlide === LOCAL_SLIDES.GENERATING) {
      setTimeout(() => {
        nextLocalSlide();
        // broadcastMSG({ STATE_SET_IMAGE: getSelectedAssetName(AssetSelection.options) });
      }, 5000);
    }
  }, [stateLocalSlide]);

  const backgroundCardWidth = 2400;
  const scrollToPage = index => {
    const activeCardPercentage = index / stateOptions.base.values.length;
    refCarousel.current.scrollTo({ left: activeCardPercentage * (backgroundCardWidth + 145), behavior: 'smooth' });
  };
  let swatchCount = 0;
  const getSwatchOption = optionName => {
    return stateOptions[optionName].values.map((option, index) => {
      swatchCount++;
      return (
        <SwatchBorder
          index={swatchCount}
          key={optionName + '_swatch_' + index}
          className={stateOptions[optionName].selected === index ? 'active' : ''}>
          <Swatch
            background={stateOptions.base.values[stateOptions.base.selected]}
            color={'none'}
            onClick={() => updateSelection(optionName, index)}>
            <img src={option} />
          </Swatch>
        </SwatchBorder>
      );
    });
  };
  const scrollCarousel = e => {
    const scrollPercent = e.target.scrollLeft / (backgroundCardWidth + 0);
    const activeCard = Math.min(Math.floor(scrollPercent * stateOptions.base.values.length), stateOptions.base.values.length - 1);
    if (activeCard !== stateOptions.base.activeCard) {
      updateSelection('base', activeCard);
    }
  };
  return (
    <LayoutOptions id="layoutOptions" headerTitle={false}>
      {stateLocalSlide === LOCAL_SLIDES.BASE && (
        <>
          <BodyHeader className={'header_' + LOCAL_SLIDES[stateLocalSlide]}>
            <StepTitle className="sans uppercase">Step 1</StepTitle>
            <p>Select Background</p>
            <h2>Choose one background.</h2>
          </BodyHeader>
          <CarouselWrapper className="carouselWrapper" onScroll={scrollCarousel} ref={refCarousel}>
            <CardCarousel backgroundCardWidth={backgroundCardWidth} className="cardCarousel" selected={stateOptions.base.selected}>
              {stateOptions.base.values.map((option, index) => {
                return (
                  <CardWrapper
                    index={index}
                    key={`cardWrapper${index}_${option}`}
                    onClick={() => {
                      const activeCardPercentage = index / stateOptions.base.values.length;
                      refCarousel.current.scrollTo({ left: activeCardPercentage * (backgroundCardWidth + 210), behavior: 'smooth' });
                    }}
                    className={`cardWrapper ${stateOptions.base.selected === index ? ' active' : ''}`}>
                    <StyledFramedVideo src={option} />
                  </CardWrapper>
                );
              })}
            </CardCarousel>
          </CarouselWrapper>
          {false && (
            <Pagination className="carouselPagination">
              {stateOptions.base.values.map((option, index) => {
                return (
                  <PaginationTouchBox
                    index={index}
                    key={`pagination${index}`}
                    onClick={() => {
                      scrollToPage(index);
                    }}>
                    <PaginationItem className={index === stateOptions.base.selected ? 'active' : ''} />
                  </PaginationTouchBox>
                );
              })}
            </Pagination>
          )}
          <BodyWrapper>
            <button
              onClick={() => {
                nextLocalSlide();
              }}>
              Select
            </button>
          </BodyWrapper>
        </>
      )}
      {stateLocalSlide !== LOCAL_SLIDES.BASE && (
        <OptionWrapper>
          {stateLocalSlide === LOCAL_SLIDES.CONFIRM && (
            <>
              <BodyWrapper className={'bodyWrapperConfirm'}>
                <BodyHeader className={'header_' + LOCAL_SLIDES[stateLocalSlide]}>
                  <StepTitle className="sans uppercase">Step 2</StepTitle>
                  <p>PREPARE FOR YOUR VIDEO</p>
                  <h2>
                    The background selection displays on
                    <br />
                    the wall behind you.
                  </h2>
                  <CameraPathExample src={cameraPathExample} />
                </BodyHeader>
                <CardWrapperPreview>
                  <StyledFramedVideo className="styledFramedVide" src={getSelectedAssetName(AssetSelection.options)} />
                </CardWrapperPreview>
                <button
                  onClick={async () => {
                    const newProfile = { ...stateProfile };
                    newProfile.selectedNFT = newProfile.selectedNFT || getAssetKey(AssetSelection.options);
                    setStateProfile(newProfile);
                    try {
                      const res = await updateProfileSelectedNFT(newProfile);
                    } catch (err) {
                      if (typeof window !== 'undefined') {
                        const restartConfirm = confirm('There had been a problem. Restart?');
                        if (restartConfirm === true) {
                          window.location.reload();
                        }
                      }
                      console.log('err', err);
                    }

                    clickNext();
                  }}>
                  Confirm
                </button>
              </BodyWrapper>
            </>
          )}
        </OptionWrapper>
      )}
      {/* {stateLocalSlide !== LOCAL_SLIDES.GENERATING && ( */}
      {stateLocalSlide !== 'NEVER' && (
        <OptionsFooter gradientFooter={stateLocalSlide === LOCAL_SLIDES.OPTIONS} className={'sans uppercase'}>
          <a
            className={stateLocalSlide === LOCAL_SLIDES.BASE || stateLocalSlide === LOCAL_SLIDES.GENERATING ? 'disabled' : ''}
            href="#"
            onClick={() => {
              if (stateLocalSlide === LOCAL_SLIDES.BASE) {
                setParentSlide(1);
              } else {
                prevLocalSlide();
              }
            }}>
            Back
          </a>
          <a
            href="#"
            className={stateLocalSlide === LOCAL_SLIDES.BASE || stateLocalSlide === LOCAL_SLIDES.GENERATING ? 'disabled' : ''}
            onClick={() => setStateLocalSlide(LOCAL_SLIDES.BASE)}>
            START OVER
          </a>
          <a
            href="#"
            onClick={() => {
              // setParentSlide(0);
              if (typeof window !== 'undefined') {
                const restartConfirm = confirm('Are you sure you want to restart?');
                if (restartConfirm === true) {
                  window.location.reload();
                }
              }
            }}>
            Cancel
          </a>
        </OptionsFooter>
      )}
    </LayoutOptions>
  );
};

export default Page;
