import styled, { keyframes } from 'styled-components';
const spinAnim = keyframes`
0% {
    transform: rotate(0deg);
}
100%{
    transform: rotate(360deg);
}`;
export const SpinnerIMG = styled.img`
  width: 176px;
  height: 194px;
  object-fit: contain;
  animation: ${spinAnim} 5s linear infinite;
`;
