import React, { useRef, useContext, useState, useEffect } from 'react';

import { PageContext } from '@/context';

import { io } from 'socket.io-client';

import {
  StreamedImage,
  MegaTitle,
  RecordingIndicator,
  LayoutVideo,
  VideoFrame,
  StyledLoadingIndicator,
  PreviewVideoFrame,
  PreviewVideoWrapper,
} from './styles';
import { BodyHeader, BodyWrapper, Icon, StepTitle } from '@/components/StyledComponents';
import { StyledFramedVideo, OptionsFooter } from '../Options/styles';
enum LOCAL_SLIDES {
  START_BUTTON = 0,
  RECORD = 1,
  GENERATING = 2,
  CONFIRM = 3,
}
const Page = ({}) => {
  const refStreamedImage = useRef(null);
  const { AssetSelection, clickNext, stateProfile } = useContext(PageContext);
  const [stateStreamImage, setStateStreamImage] = useState(false);

  const [socket, setSocket] = useState(null);

  const [stateLocalSlide, setStateLocalSlide] = useState(LOCAL_SLIDES.START_BUTTON);
  const [stateCountdown, setStateCountdown] = useState(5);
  const [stateOptions, setStateOptions] = useState(AssetSelection.options);

  const [stateGeneratingCountdown, setStateGeneratingCountdown] = useState(0);

  useEffect(() => {
    const socketUrl = process.env.GATSBY_DISPLAY_SERVER;
    const newSocket = io(socketUrl);
    newSocket.on('connect', () => {
      console.log('connected to socket');
    });
    newSocket.on('recordingUploaded', (claimID, data) => {
      clearTimeout(generatingTimeout);
      // console.log('recordingUploaded', claimID, data);
      nextLocalSlide();
    });
    newSocket.on('image', image => {
      // console.log('refStreamedImage.current', refStreamedImage.current);
      if (refStreamedImage.current) {
        refStreamedImage.current.src = `data:image/jpeg;base64, ${image}`;
      }
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  const startCountdown = () => {
    const { claimID } = stateProfile;
    socket.emit('beginRecording', claimID);
    const interval = setInterval(() => {
      setStateCountdown(val => {
        if (val === -10) {
          clearInterval(interval);
          nextLocalSlide();
          return val;
        }
        return val - 1;
      });
    }, 1000);
  };
  useEffect(() => {
    if (stateLocalSlide === LOCAL_SLIDES.START_BUTTON) {
      setStateCountdown(5);
    }
    if (stateLocalSlide === LOCAL_SLIDES.RECORD) {
      startCountdown();
    }
    if (stateLocalSlide === LOCAL_SLIDES.START_BUTTON) {
      setTimeout(() => {
        nextLocalSlide();
      }, 5000);
    }
    if (stateLocalSlide === LOCAL_SLIDES.GENERATING) {
      generatingTimeout = setTimeout(() => {
        nextLocalSlide();
      }, 20000);
    }
  }, [stateLocalSlide]);
  let generatingTimeout;
  const nextLocalSlide = () => {
    setStateLocalSlide(stateLocalSlide + 1);
  };
  const prevLocalSlide = () => {
    setStateLocalSlide(stateLocalSlide - 1);
  };

  return (
    <LayoutVideo fs={true} headerTitle={false}>
      {(stateLocalSlide === LOCAL_SLIDES.START_BUTTON || stateLocalSlide === LOCAL_SLIDES.RECORD) && (
        <>
          {stateLocalSlide === LOCAL_SLIDES.START_BUTTON && <h2>Make sure your body is in this frame</h2>}
          <VideoFrame>
            <StreamedImage src={null} ref={refStreamedImage} className={stateLocalSlide === LOCAL_SLIDES.RECORD ? 'active' : ''} />
            {stateLocalSlide === LOCAL_SLIDES.RECORD && stateCountdown > 0 && <MegaTitle className="airWeight">{stateCountdown}</MegaTitle>}
            {stateLocalSlide === LOCAL_SLIDES.RECORD && stateCountdown <= 0 && <RecordingIndicator>Recording</RecordingIndicator>}
          </VideoFrame>
        </>
      )}

      {stateLocalSlide === LOCAL_SLIDES.GENERATING && (
        <>
          <StyledLoadingIndicator>
            <h2>Generating Video</h2>
            <p>You will be able to save your video and post it to social media</p>
          </StyledLoadingIndicator>
          <OptionsFooter gradientFooter={stateLocalSlide === LOCAL_SLIDES.OPTIONS} className={'sans uppercase'}>
            <div> </div>
            <div> </div>
            <a
              href="#"
              onClick={() => {
                // setParentSlide(0);
                if (typeof window !== 'undefined') {
                  const restartConfirm = confirm('Are you sure you want to restart?');
                  if (restartConfirm === true) {
                    window.location.reload();
                  }
                }
              }}>
              Cancel
            </a>
          </OptionsFooter>
        </>
      )}

      {stateLocalSlide === LOCAL_SLIDES.CONFIRM && (
        <>
          <BodyWrapper className={'bodyWrapperConfirm'}>
            <PreviewVideoFrame>
              <StyledFramedVideo className="styledFramedVideo" src={`${process.env.GATSBY_AWS_VIDEO_URL}${stateProfile.claimID}.mp4`} />
            </PreviewVideoFrame>
            <BodyHeader className={`header_${LOCAL_SLIDES[stateLocalSlide]}`}>
              <StepTitle className="sans uppercase">Step 3</StepTitle>
              <p>PREVIEW</p>
            </BodyHeader>

            <PreviewVideoWrapper>
              <h2>
                Press confirm to continue to
                <br />
                get a copy of this video.
              </h2>
            </PreviewVideoWrapper>
            <button
              onClick={() => {
                clickNext();
              }}>
              Confirm
            </button>
            <a
              className={'sans uppercase backButton'}
              href="#"
              onClick={() => {
                setStateLocalSlide(LOCAL_SLIDES.START_BUTTON);
              }}>
              Restart Video
            </a>
          </BodyWrapper>
        </>
      )}
    </LayoutVideo>
  );
};

export default Page;
