import styled from 'styled-components';

import Layout from '@/components/atoms/Layout';
import Card from '@atoms/Card';
import FramedVideo from '@/components/atoms/FramedVideo';

import { FadeIn, AnimationIntro } from '@/components/StyledComponents';
/*
Create a local instance of this to inherit stlyes properly
*/

export const StyledFramedVideo = styled(FramedVideo)``;
export const StyledCard = styled(Card)``;
export const CardWrapper = styled.div`
  position: relative;
  opacity: 0;
  animation: ${FadeIn} 0.25s ${({ index }) => index * 0.25}s ease-out 1 forwards;
  transition: all 0.3s ease-in-out;
  @media (orientation: portrait) {
    max-width: 340px;
    max-height: 450px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    &.active {
      max-width: 426px;
      max-height: 563px;
    }
  }
  overflow: hidden;
  border-radius: 0;
  border: 2px solid white;

  margin-right: 15px;
  margin-left: 15px;
  .videoFrame {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.active {
    .videoFrame {
    }
  }
`;
export const CardWrapperPreview = styled.div`
  width: 345px;
  border: 1px solid white;
  margin: 0 auto;
  margin-bottom: 142px;
`;
export const LayoutOptions = styled(Layout)`
  .headerTitle {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .bodyWrapperConfirm {
    margin-top: -10px;
    opactiy: 0;
    transform: translateY(5vh);
    animation: ${AnimationIntro} 0.5s ease-out forwards;
    ${StyledCard} {
      width: 408px !important;
      height: unset;
      max-width: unset;
      max-height: unset;
      margin-bottom: 100px;
    }
  }
  .header_OPTIONS {
    margin-bottom: 75px;
  }
  .header_CONFIRM {
    margin-bottom: 42px;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100vw;
  overflow-x: scroll;
  height: 585px;
  position: relative;
  margin-bottom: 136px;
  padding-bottom: 24px;
`;
export const CardCarousel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0;

  width: Calc(${props => props.backgroundCardWidth}px + 45px);
  height: 100%;
  margin-left: Calc(45vw - 190px);
  margin-right: 50vw;
  left: ${props => {
    if (props.selected === 0) {
      return '12.5';
    } else if (props.selected === 2) {
      return '-12.5';
    }
    return 0;
  }}vw;
  left: 0;
  ${StyledCard} {
    margin: 0 11px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    opacity: 0.85;
    &.active {
      opacity: 1;
    }
    object-fit: cover;
    object-position: center;
    @media (orientation: portrait) {
      width: 100%;
      height: 100%;
    }
  }
`;
export const OptionWrapper = styled.div`
  padding-bottom: 25vh;

  ${StyledCard} {
    width: unset;
    max-width: unset;
  }
`;
export const Label = styled.h2``;
export const SwatchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  flex-wrap: wrap;
  margin: 40px auto 85px auto;
`;

export const Swatch = styled.div`
  background-color: ${props => props.color};
  &::after {
    content: ' ';
    background: URL(${props => props.background}) no-repeat center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.25;
  }
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: all 0.1s ease-out;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    background-size: contain;
  }
`;
export const SwatchBorder = styled.div`
  border-radius: 30px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 246px;
  height: 310px;

  transition: all 0.1s ease-out;
  margin: 13px 10px;

  opacity: 0;
  animation: ${FadeIn} 0.125s ${({ index }) => 0.5 + index * 0.0625}s ease-out 1 forwards;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.active {
    border-color: white;

    ${Swatch} {
      width: 226px;
      height: 290px;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 240px;
  margin-bottom: 32px;
`;
export const PaginationTouchBox = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PaginationItem = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #2b2b2b;
  transition: all 0.25s ease-out;
  &.active {
    background-color: #c4c4c4;
  }
`;
export const OptionsFooter = styled.footer`
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 200px;
  font-size: 1.25rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
  padding: 0 3rem;
  background: ${props => (props.gradientFooter === true ? 'linear-gradient(rgba(0, 0, 0, 0), #000000)' : '')};
  pointer-events: none;
  margin-bottom: 10px;
  a {
    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }
    width: 175px;
    pointer-events: auto;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
`;
export const CameraPathExample = styled.img`
  margin-top: 58px;
`;
