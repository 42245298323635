import React, { ReactNode, useEffect } from 'react';

import { Wrapper, HeaderTitle, CheaterSwitch } from './styles';

type TLayoutProps = {
  children: ReactNode;
};

// import logoSrc from '@/images/sf_small.png';
import srcHeaderTitle from '@/images/ferragamo.svg';
const Layout = ({ children, fs, onClick, headerIntro, headerTitle, className = '' }: TLayoutProps) => {
  const goFullscreen = () => {
    if (typeof window !== undefined && fs === true) {
      try {
        window.document.body.webkitRequestFullScreen();
      } catch (err) {
        console.log('fullscreen ineffective');
      }
      // if (window.requestFullscreen) {
      //   window.requestFullscreen();
      // }
    }
  };
  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('keyup', e => {
        // console.log(e.key);
        if (e.key === 'f') {
          // goFullscreen();
        }
      });
    }
  }, []);
  return (
    <Wrapper
      className={`layoutWrapper ${className}`}
      onClick={() => {
        goFullscreen();
        if (onClick) {
          onClick();
        }
      }}>
      {headerTitle && (
        <>
          <HeaderTitle src={srcHeaderTitle} className="headerTitle" alt="headerTitle" />
        </>
      )}

      {children}
    </Wrapper>
  );
};

export default Layout;
