import React, { useContext, useState, useEffect } from 'react';

import { PageContext } from '@/context';
import { getSelectedNFTName } from '@/utils';

import { Link } from 'gatsby';
import { BodyHeader } from '@/components/StyledComponents';
import { StyledFramedVideo, StyledFramedVideoNFT, LayoutClaim, Row, Col, StyledLoadingIndicator } from './styles';
import { getData } from '@/utils';
import { updateProfileWalletID } from '@/utils';

const Page = ({ claimID = '' }) => {
  const [stateClaimInfo, setStateClaimInfo] = useState(null);
  const [stateFormData, setStateFormData] = useState({ email: '', walletID: '' });

  useEffect(async () => {
    if (claimID) {
      const res = await getData({ params: { claimID }, method: 'GET' });
      console.log('res', res);
      const resultObject = { ...res?.body.inventory, failed: Object.keys(res?.body.inventory).length === 0 };
      console.log(resultObject);
      // setStateClaimInfo({ ...stateClaimInfo, failed: true });
      setStateClaimInfo(resultObject);
    }
  }, [claimID]);
  const { AssetSelection } = useContext(PageContext);
  const getMobileOS = () => {
    if (typeof window !== 'undefined') {
      const ua = navigator.userAgent;
      if (/android/i.test(ua)) {
        return 'Android';
      } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return 'iOS';
      }
    }
    return 'Other';
  };

  // const vidSrc = getSelectedNFTName(AssetSelection.options);
  const boothVideoSrc = `https://ferragamo-nft.com/video/${claimID}.mp4`;
  const webshareFile = (blob, src) => {
    const file = new File([blob], 'FerragamoXShxpir.mp4', {
      type: 'video/mp4',
    });
    const filesArray = [file];
    const shareData = { files: filesArray };
    if (typeof window !== 'undefined') {
      if ((navigator.canShare && navigator.canShare(shareData)) || navigator.share) {
        // Adding title afterwards as navigator.canShare just
        // takes files as input
        shareData.title = 'Ferragamo X Shxpir';

        navigator
          .share(shareData)
          .then(() => console.log('Share was successful.'))
          .catch(error => console.log('Sharing failed', error));
      } else {
        console.log("Your system doesn't support sharing files.");
        // window.open(vidSrc, '_blank');
        const link = document.createElement('a');
        link.href = src;
        link.target = '_blank';
        link.download = 'FerragamoXShxpir.mp4';
        link.click();
      }
    }
  };
  const isEmail = email => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const isWalletAddress = address => {
    const test = /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
    console.log('test', test);
    return test;
  };

  const fetchFile = async (fileIn, fileOut) => {
    console.log('attempting fetch');
    console.log('getMobileOS', getMobileOS());
    alert('Your download will begin momentarily');
    if (getMobileOS() === 'Other') {
      if (typeof window !== 'undefined') {
        if (window.innerWidth > 1024) {
          console.log('Link download with extension');
          const link = document.createElement('a');
          link.href = fileOut;
          link.target = '_blank';
          link.download = 'FerragamoXShxpir.mp4';
          link.click();
          return;
        }
      }
    }
    try {
      const blob = await fetch(fileIn, { method: 'GET', mode: 'no-cors' })
        .then(function (response) {
          return response.blob();
        })
        .then(blob => webshareFile(blob, fileOut));
    } catch (err) {
      console.log("Your system doesn't support sharing files.");
      // window.open(vidSrc, '_blank');
      const link = document.createElement('a');
      link.href = fileOut;
      link.target = '_blank';
      link.download = 'FerragamoXShxpir';
      link.click();
    }
  };
  return (
    <LayoutClaim headerTitle>
      {stateClaimInfo?.selectedNFT !== undefined && stateClaimInfo?.hasWallet !== undefined && (
        <Row className="rowVideo" key="claim_video">
          <StyledFramedVideo
            alt="Share"
            pointer={true}
            onClick={() => {
              fetchFile(boothVideoSrc, boothVideoSrc);
              //   fetch(boothVideoSrc)
              //     .then(function (response) {
              //       return response.blob();
              //     })
              //     .then(blob => webshareFile(blob, boothVideoSrc));
            }}
            className="framedVideo framedVideoBooth"
            src={boothVideoSrc}
          />
          <Col className="colNFT">
            <BodyHeader className="bodyHeader">
              <h2>Share your video</h2>
              {getMobileOS() === 'iOS' && (
                <p>
                  Click the <em>Share button</em> and choose <em>Save Video</em> to download your video. You can share your video to TikTok
                  or any other social media channel from your Photos.
                </p>
              )}
              {getMobileOS() !== 'iOS' && (
                <p>
                  Click the <em>Share button</em> to share your video to TikTok or any other social media channel!
                </p>
              )}
            </BodyHeader>
            <button
              onClick={() => {
                fetchFile(boothVideoSrc, boothVideoSrc);
                // fetch(boothVideoSrc)
                //   .then(function (response) {
                //     return response.blob();
                //   })
                //   .then(blob => webshareFile(blob, boothVideoSrc));
              }}>
              Share
            </button>
          </Col>
        </Row>
      )}

      <footer>
        2023© Salvatore Ferragamo S.P.A. — P.IVA 02175200480 -{' '}
        <a href="https://www.ferragamo.com/shop/ca/en/sf/legal/legal-notice#chapter2" target="_blank">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href="https://www.ferragamo.com/shop/ca/en/sf/legal/terms-conditions" target="_blank">
          Terms and Conditions
        </a>
      </footer>
      <footer className="mobileOnly">
        2022© Salvatore Ferragamo S.P.A.
        <br />
        P.IVA 02175200480
        <br />
        <a href="https://www.ferragamo.com/shop/ca/en/sf/legal/legal-notice#chapter2" target="_blank">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href="https://www.ferragamo.com/shop/ca/en/sf/legal/terms-conditions" target="_blank">
          Terms and Conditions
        </a>
      </footer>
    </LayoutClaim>
  );
};

export default Page;
