import styled from 'styled-components';
import { BodyWrapper } from '@/components/StyledComponents';
import Img from 'gatsby-image'; // to take image data and render it

import { FadeIn } from '@/components/StyledComponents';
export const InputRow = styled.div`
  display: flex;
  flex-direction: row;

  width: 54vw;
  margin: 0 auto;
  justify-content: space-between;
  input:first-child {
    margin-right: 1rem;
  }
`;
export const StyledBodyWrapper = styled(BodyWrapper)`
  max-width: 54vw;
  margin-top: 150px;
  h2 {
    margin-bottom: 60px;
    font-weight: 300;
  }
  input {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    position: absolute;
    bottom: 106px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const CheckboxLabel = styled.div``;
export const CheckBoxWrapper = styled.label`
  width: 48vw;
  display: flex;
  margin: 0 auto;
  margin-top: 35px;
  text-align: left;
`;
export const CheckBox = styled.div`
  width: 100%;
  min-width: 40px;
  max-width: 40px;
  margin-right: 16px;
  margin-left: 5px;
  margin-top: 0px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid white;
  position: relative;
  &.checked::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 33px;
    border-radius: 12px;
    background: white;
  }
`;
export const StepList = styled.ul`
  display: flex;
  margin: 0 auto;
  padding: 0;
  justify-content: space-between;
  width: 100%;
`;
export const Step = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 16%;
  opacity: 0;
  animation: ${FadeIn} 0.25s ${({ index }) => 0 + index * 0.15}s ease-out 1 forwards;
  .icon {
    margin-bottom: 40px;
    height: 100px;
  }
  p {
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.5rem;
  }
  margin-bottom: 110px;
`;

export const StepDescriptionWrapper = styled.div`
  width: 50vw;
  margin: 0 auto;
`;
export const CheaterSwitch = styled.div`
  position: absolute;
  top: 287px;
  left: 745px;
  width: 50px;
  height: 50px;
  opacity: 0;
`;
