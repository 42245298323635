import React from 'react';

import { CardWrapper, CardImage } from './styles';

import videoFrame from '@/images/videoFrame.png';
type TCard = {
  src: string;
  className?: string;
  key?: string;
  cardSelected?: number;
  index?: number;
  onClick?: () => void;
};

const Card = ({ className, src, key, onClick, children }: TCard) => {
  return (
    <CardWrapper src={src} onClick={onClick} className={className}>
      {children}
      <CardImage src={src} key={key} />
      <CardImage src={videoFrame} className="videoFrame" />
    </CardWrapper>
  );
};

export default Card;
