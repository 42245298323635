import styled from 'styled-components';

import Card from '@atoms/Card';
import Layout from '@atoms/Layout';
import FramedVideo from '@/components/atoms/FramedVideo';

import { AnimationIntro } from '@/components/StyledComponents';
export const VideoFrame = styled.div`
  border: 2px solid white;
  aspect-ratio: 12/16;
  width: 51.3vw;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const LayoutFinal = styled(Layout)`
  .bodyHeader {
    margin-bottom: 920px;
    max-width: 69vw;
  }
  .bodyWrapper {
    display: flex;
    width: 71.1vw;
    margin-bottom: 0;
  }
  button {
    border-radius: 0;
    width: 550px;
    height: 100px;
    opacity: 0;
    transform: translateY(5vh);
    animation: ${AnimationIntro} 0.5s 0.5s ease-out forwards;
  }
`;

export const StyledFramedVideo = styled(FramedVideo)`
  width: 100%;
  opacity: 0;
  transform: translateY(5vh);
  animation: ${AnimationIntro} 0.5s 0s ease-out forwards;
`;
export const BoothVideo = styled(StyledFramedVideo)`
  margin-left: 0;
  opacity: 0;
  transform: translateY(5vh);
  animation: ${AnimationIntro} 0.5s 0.25s ease-out forwards;
`;
