import styled, { keyframes } from 'styled-components';

import Layout from '@atoms/Layout';

import { AnimationIntro } from '@components/StyledComponents';
export const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin-top: 55px;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(10vh);
    animation: ${AnimationIntro} 0.5s 0.25s ease-out 1 forwards;
  }
`;

export const Logo = styled.div`
  width: 583px;
  height: auto;
  max-width: Calc(100vw - 11rem);
  margin: 100px auto 0;
  aspect-ratio: 1166/135;

  opacity: 0;
  transform: translateY(10vh);
  animation: ${AnimationIntro} 0.5s 0s ease-out 1 forwards;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  @media (max-width: 768px) {
    margin-top: 57px;
    width: 63vw;
  }
`;
