import styled, { keyframes } from 'styled-components';

import Card from '@atoms/Card';

import videoFrame from '@/images/videoFrame.png';
import Layout from '@/components/atoms/Layout';
import { Icon, FadeIn, AnimationIntro } from '@/components/StyledComponents';
import LoadingIndicator from '../../LoadingIndicator';

const cameraMove = keyframes`
  0%{
    opacity: 0;
    left:275px;
    top:10px;
  }
  5% {
    opacity: 1;
    left:275px;
    top:10px;
  }
  50%{
    top:-40px;
  }
  95% {
    left:650px;
    top:10px;
    opacity: 1;
  }
  100% {
    left:650px;
    top:10px;
    opacity: 0;
  }
`;

export const PreviewVideoWrapper = styled.div`
  margin-top: 56.5vh;
  h2 {
    margin-top: 0;
  }
`;
export const IconWrapper = styled.div`
  position: relative;
  & > .cImask {
    position: absolute !important;
    left: 0;
    top: 0;
  }
`;
export const StyledPath = styled.img`
  position: relative !important;
  height: 74px;
`;
export const StyledIcon = styled.img`
  position: absolute !important;
  animation: ${cameraMove} 2.5s infinite alternate ease-in-out;
  animation-direction: normal;
  height: 74px;
`;

export const StyledCard = styled(Card)``;
export const StreamedImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 0px;
  oveflow: hidden;
  z-index: -1;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  &.active {
    opacity: 1;
  }
`;
export const LayoutVideo = styled(Layout)`
  .header_ONBOARDING {
    margin-bottom: 80px;
  }
  h2 {
    margin-top: 18.5vh;
    width: 52%;
    font-weight: 500;
  }
  ${Icon} {
    height: 74px;
    margin-bottom: 80px;
  }
  .styledFramedVideo {
    width: 345px;
    opacity: 0;
    animation: ${FadeIn} 0.25s 1s ease-out forwards;
  }
  .styledFramedVideoOnboarding {
    margin-top: 50px;
  }
  button {
    position: absolute;
    bottom: 135px;
    left: 50%;
    transform: translateX(-50%);
  }
  .backButton {
    font-size: 1.25rem;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .bodyWrapperConfirm {
    .styledFramedVideo {
      width: 100%;
      margin-top: 00px;
    }
    ${StyledCard} {
      width: 500px;
      height: unset;
      max-width: unset;
      max-height: unset;
      margin-bottom: 100px;
    }
    h2 {
      margin-bottom: 50px;
    }
    p {
      width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
    button {
      bottom: 138px;
    }

    margin-top: -10px;
  }
  .headerTitle {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .header_CONFIRM {
    margin-bottom: 42px;
  }
`;
export const CamerIcon = styled.img`
  aspect-ratio: 654/392;
  width: 28%;
`;
export const VideoFrame = styled.div`
  border: 2px solid white;
  aspect-ratio: 12/16;
  width: 51.3vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;

  animation: ${FadeIn} 0.5s ease-out forwards;
`;

export const PreviewVideoFrame = styled(VideoFrame)`
  width: 38vw;
  top: 41.5vh;
`;
export const MegaTitle = styled.h1`
  font-size: 29.2285rem;
  margin: 20px 0 0 0;
`;
export const RecordingAnimation = keyframes`
0%{opacity:0.5;}
50%{opacity:1;}
100%{opacity:0.5;}
`;
export const RecordingIndicator = styled.h2`
  color: red;
  animation: ${RecordingAnimation} 1s infinite;
`;
export const StyledLoadingIndicator = styled(LoadingIndicator)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 47vw;
  margin-top: 35vh;
  h2 {
    margin-top: 0;
  }
`;
